import { Injectable, Signal, WritableSignal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthLoaderService {
  public isLoading: WritableSignal<boolean> = signal<boolean>(false);

  constructor() {}

  public setLoading(value: boolean): void {
    this.isLoading.set(value);
  }
}
